<template>
  <navbar></navbar>
  <div>
    <section class="loginpagebackground" style="padding: 150px 0px">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div>
              <div class="card m-3" v-if="!items">
                <h5 class="card-header">Forgot Password</h5>
                <div class="card-body">
                  <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                  >
                    <div class="form-row">
                      <div class="form-group col">
                        <label>Email / Phone</label>
                        <Field
                          name="customerEmailPhone"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.customerEmailPhone }"
                          placeholder="Email/Phone Number"
                        />
                        <div class="invalid-feedback">
                          {{ errors.customerEmailPhone }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group" style="text-align: end">
                      <button type="submit" class="btn btn-primary mr-1">
                        Send
                      </button>
                    </div>
                    <div class="text-center">
                      <div>
                        <a href="/customerlogin" class="backtol"
                          >Back to Login</a
                        >
                      </div>
                    </div>
                  </Form>
                  <div
                    v-if="message"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ message }}
                  </div>
                </div>
              </div>

              <div class="card m-3" v-if="items">
                <h5 class="card-header">Otp Verification</h5>
                <div class="card-body comm-space-lab">
                  <Form
                    class="other"
                    @submit="onSubmitotp"
                    :validation-schema="schemaotp"
                    v-slot="{ errors }"
                  >
                    <div class="">
                      <div class="form-group">
                        <label>OTP</label>
                        <Field
                          name="otp"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.otp }"
                          placeholder="Enter your OTP"
                        />
                        <div class="invalid-feedback">{{ errors.otp }}</div>
                      </div>
                      <div class="form-group">
                        <label>New Password</label>
                        <div style="position: relative">
                          <Field
                            name="password"
                            :type="passwordCls"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password }"
                            placeholder="Enter your password"
                          />
                          <button
                            type="button"
                            @click="changeType()"
                            id="btnToggle"
                            class="toggle passeye"
                          >
                            <i
                              id="eyeIcon"
                              v-if="passwordCls == 'text'"
                              class="fas fa-eye-slash"
                            ></i>
                            <i
                              id="eyeIcon"
                              v-if="passwordCls == 'password'"
                              class="fa fa-eye"
                            ></i>
                          </button>
                          <div class="invalid-feedback">
                          {{ errors.password }}
                        </div>
                        </div>
                       
                      </div>
                      <div class="form-group">
                        <label>Confirm Password</label>
                        <div style="position: relative">
                          <Field
                            name="confirmPassword"
                            :type="passwordClsC"
                            class="form-control"
                            :class="{ 'is-invalid': errors.confirmPassword }"
                            placeholder="Re-enter your password"
                          />
                          <button
                            type="button"
                            id="btnToggle"
                            @click="changeTypeC()"
                            class="toggle passeye"
                          >
                            <i
                              id="eyeIcon"
                              v-if="passwordClsC == 'text'"
                              class="fas fa-eye-slash"
                            ></i>
                            <i
                              id="eyeIcon"
                              v-if="passwordClsC == 'password'"
                              class="fa fa-eye"
                            ></i>
                          </button>
                          <div class="invalid-feedback">
                          {{ errors.confirmPassword }}
                        </div>
                        </div>
                       
                      </div>

                      <div class="form-group" style="text-align: end">
                        <button type="submit" class="btn btn-primary mr-1">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                  <div
                    v-if="message"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import userService from "@/services/user.service";
import { Form, Field } from "vee-validate";
import * as yup from "yup";

export default {
  name: "CustomerForgotPassword",
  components: {
    Form,
    Field,
    navbar,
  },
  data() {
    const schema = yup.object().shape({
      customerEmailPhone: yup
        .string("Enter your Email/Phone Number")
        // .email("Enter a valid email")
        .required("Email/Phone Number is required")
        .test("test-name", "Enter Valid Phone/Email", function (value) {
          /* eslint-disable-next-line */
          const emailRegex =/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }),
    });
    const schemaotp = yup.object().shape({
      otp: yup.string().required("Otp is required"),
      password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required")
        .min(6, "Password must be at least 6 characters"),
    });

    return {
      schema,
      items: "",
      schemaotp,
      message: "",
      successful: false,
      // submitted: false,
      passwordCls: "password",
      passwordClsC: "password",
    };
  },
  methods: {
    changeType() {
      this.passwordCls = this.passwordCls == "password" ? "text" : "password";
    },
    changeTypeC() {
      this.passwordClsC = this.passwordClsC == "password" ? "text" : "password";
    },

    onSubmit(values) {  console.log(values);
      this.message = "";
      // this.submitted =true;
      userService.forgotpassword(values.customerEmailPhone).then(
        (data) => {
          this.items = data.data.Data[0];
          console.log(data.data.Data[0].otp);
        },
        (error) => {
          this.message =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    onSubmitotp(values) {
      this.message = "";
      // this.submitted=true;
      userService.forgotpasswordotp(values, this.items).then(
        (data) => {
          this.message = data.data.Message;
          this.successful = true;
          if (data.data.Message == "Success") {
            this.message = "Password Changed Successfully";
            this.goToLogin();
          } else {
            this.message = data.data.Message;
          }
        },
        (error) => {
          this.message =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    goToLogin() {
      setTimeout(() => this.$router.push("/customerlogin"), 2000);
    },
  },
};
</script>
